export enum UserPermission {
  AllPermissions="جميع الصلاحيات",
  AddStudent="اضافة طالب",
  ShowStudentsProfile="عرض بروفايلات الطلاب",
  ShowStudentsList="عرض قائمة الطلاب",
  QuickAddStudent="إضافة سريعة",
  AddStudents="إضافة طالب",
  ImortStudents="استيراد طلاب",
  ShowFile="مشاهدة ملف",
  DownlaodFile="تحميل ملف",
  EditStudentProfile="تعديل بروفايل طالب",
  ShowStaffs="عرض الكادر التدريسي",
  AddStaff="إضافة كادر",
  EditStaff="تعديل كادر",
  DeleteStaff="حذف كادر",
  ShowTeachersList="عرض قائمة المدرسين",
  ShowAttendanc="عرض الحضور",
  TakeAttendance="أخذ الحضور",
  ShowMessages="عرض الرسائل",
  ShowExamsList="عرض قائمة الامتحانات",
  ShowMarks="عرض العلامات",
  AddMark="اضافة علامة",
  ShowSubjects="عرض المواد",
  AddSubject="إضافة مادة",
  EditSubject="تعديل مادة",
  DeleteSubject="حذف مادة",
  ShowClasses="عرض السنوات",
  AddClass="إضافة صف",
  EditClass="تعديل صف",
  DeleteClass="تعديل صف",
  Infractions="الانضباط",
  ShowGaurdians="عرض أولياء الأمور",
  CreatAccount="توليد حساب",
  EditProfile="تعديل بروفايل",
  RolesAndPermissions="الأدوار والصلاحيات",
  ExamTypes="أنواع الامتحان",
  GradeNames="أسماء السنوات",
  GroupCategories="تصنيفات المجموعات",
  Groups="المجموعات",
  Terms="الفصول",
  SectionsNames="أسماء الشعب",
  Jobs="المناصب",
  InfractionsTypes="أنواع المخالفة",
  AttendaceTypes="أنواع الغياب",
  DeactivationTypes="أسباب التعطيل",
  Courses="الكورسات",
  CoursesTypes="أنواع الكورسات",
  Schedule="جدول الدوام",
  message="عرض الرسائل",
  setting="الإعدادات",
  register_user="تسجيل طلاب",
  forms="عرض معلومات الاستمارة",
  feeses="الأقساط",
  studentsFeeses="أقساط الطلاب",
  courses="الكورسات",
  type_courses="أنواع الكورسات",
  academic_stages="الكليات",
  discounts = "الحسومات"

  


  
}
