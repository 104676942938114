import { environment } from 'src/environments/environment';
import { UserPermission } from '../shared/auth.roles';
const adminRoot = '/app';

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  permission?: UserPermission[];
}

const data: IMenuItem[] = [

  //students tab
  {
    icon: 'iconsminds-student-male-female',
    label: 'menu.students',
    to: `${adminRoot}/students`,
    permission: [UserPermission.ShowStudentsProfile,],

    /*subs: [
      {
        icon: 'iconsminds-student-male-female',
        label: 'menu.studentsList',
        to: `${adminRoot}/students/view`,
        //roles: [UserRole.Admin],
      }
      ,{
        icon: 'iconsminds-add-user',
        label: 'menu.addStudent',
        to: `${adminRoot}/students/add`,
        //roles: [UserRole.Admin],
      },
    ],*/
  },

  //Teaching staff
  {
    icon: 'iconsminds-business-man-woman',
    label: 'menu.Teaching-staff',
    to: `${adminRoot}/teachingStaff`,
    permission: [UserPermission.ShowStaffs],
    // subs: [
    //   {
    //     icon: 'iconsminds-business-man-woman',
    //     label: 'menu.teachersList',
    //     to: `${adminRoot}/teachingStaff/teachers`,
    //   }
    // ],
  },

  //attendance
  // {
  //   icon: 'simple-icon-check',
  //   label: 'menu.attendance',
  //   to: `${adminRoot}/attendance`,
  //   permission: [UserPermission.ShowAttendanc],

  // }, 
  {
    icon: 'simple-icon-check',
    label: 'menu.form',
    to: `${adminRoot}/university`,
    permission: [UserPermission.register_user],

  },
  {
    icon: 'simple-icon-check',
    label: 'menu.form1',
    to: `${adminRoot}/university/studentforuniversity`,
    permission: [UserPermission.forms]


  },
  {
    icon: 'simple-icon-envelope-open',
    label: 'menu.messages.messages',
    to: `${adminRoot}/messages`,
    permission: [UserPermission.ShowMessages],

    subs: [

      {
        icon: 'iconsminds-mail-forward',
        label: 'menu.messages.sent',
        to: `${adminRoot}/messages/sent`,
      }
      , {
        icon: 'simple-icon-envelope',
        label: 'menu.messages.received',
        to: `${adminRoot}/messages/received`,
      },

      {
        icon: 'simple-icon-envelope-open',
        label: 'menu.messages.draft',
        to: `${adminRoot}/messages/draft`,
      }
      , {
        icon: 'simple-icon-briefcase',
        label: 'menu.messages.archives',
        to: `${adminRoot}/messages/archive`,
      }
      , {
        icon: 'simple-icon-trash',
        label: 'menu.messages.wastepaper',
        to: `${adminRoot}/messages/delete`,
      }
      , {
        icon: 'simple-icon-trash',
        label: 'menu.messages.accept',
        to: `${adminRoot}/messages/accept`,
      }

    ]
  },
  //exams
  // {
  //   icon: 'simple-icon-question',
  //   label: 'menu.exams',
  //   to: `${adminRoot}/exams`,
  //   permission: [UserPermission.ShowExamsList],

  // },

  //exams
  /*{
    icon: 'iconsminds-testimonal',
    label: 'menu.marks',
    to: `${adminRoot}/marks`,
  },*/

  //subjects
  // {
  //   icon: 'iconsminds-books',
  //   label: 'menu.subjects',
  //   to: `${adminRoot}/subjects`,
  //   permission: [UserPermission.ShowSubjects],

  // },
  //classes
  {
    icon: 'iconsminds-student-hat',
    label: 'university.classes',
    to: `${adminRoot}/classes`,
    permission: [UserPermission.ShowClasses,],

  },
  //infractions
  // {
  //   icon: 'simple-icon-minus',
  //   label: 'menu.infractions',
  //   to: `${adminRoot}/infractions`,
  //   permission: [UserPermission.Infractions],

  //   /*subs: [
  //       {
  //       icon: 'iconsminds-business-man-woman',
  //       label: 'menu.infractions.',
  //       to: `${adminRoot}/teachingStaff/teachers`,
  //     }
  //   ],*/
  // },
  //guardians
  {
    icon: 'iconsminds-business-man-woman',
    label: 'menu.guardians',
    to: `${adminRoot}/guardians`,
    permission: [UserPermission.ShowGaurdians],

  },
  //resports
  // {
  //   icon: 'iconsminds-newspaper',
  //   label: 'menu.reports',
  //   to: `${adminRoot}/reports`,
  //    subs: [
  //      {
  //      icon: 'iconsminds-pie-chart-3',
  //      label: 'menu.one-section-avg',
  //      to: `${adminRoot}/reports/oneSectionAvg`,
  //    },
  //    {
  //     icon: 'iconsminds-pie-chart-3',
  //     label: 'menu.multi-section-avg',
  //     to: `${adminRoot}/reports/multiSectionAvg`,
  //   }
  //  ],

  // },
  //homeworks
  //  {
  //   icon: 'iconsminds-notepad text-danger',
  //   label: 'menu.homeworks',
  //   to: `${adminRoot}/homeworks`,

  // },
  //ratings
  // {
  //   icon: 'simple-icon-star text-danger',
  //   label: 'menu.ratings',
  //   to: `${adminRoot}/ratings`,

  // },
  //curriculum
  // {
  //   icon: 'iconsminds-books text-danger',
  //   label: 'menu.curriculum',
  //   to: `${adminRoot}/curriculum`,
  // },

  //premiums
  // {
  //   icon: 'iconsminds-coins',
  //   label: 'menu.feeses',
  //   to: `${adminRoot}/feeses`,
  //   permission: [UserPermission.feeses],


  // },

  //student-fees
  {
    icon: 'iconsminds-business-man-woman',
    label: 'menu.Student-Fees',
    to: `${adminRoot}/student-fees`,
    permission: [UserPermission.studentsFeeses],

  },
  {
    icon: 'iconsminds-box-with-folders',
    label: 'menu.logs',
    to: `${adminRoot}/logs`,
  },
  //buses
  // {
  //   icon: 'iconsminds-bus-2 text-danger',
  //   label: 'menu.buses',
  //   to: `${adminRoot}/buses`,

  // },
  //archives
  // {
  //   icon: 'iconsminds-file-zip text-danger',
  //   label: 'menu.archives',
  //   to: `${adminRoot}/archives`,

  // },
  //roles-and-permissions
  {
    icon: 'simple-icon-key',
    label: 'menu.roles-and-permissions',
    to: `${adminRoot}/rolesAndPermissions`,
    permission: [UserPermission.RolesAndPermissions],

  },

  //setting tab
  {
    icon: 'simple-icon-settings',
    label: 'menu.setting.setting',
    to: `${adminRoot}/setting`,

    subs: [
      {
        icon: 'iconsminds-calendar-1',
        label: 'menu.setting.academic-year',
        to: `${adminRoot}/setting/academicYear`,

      },
      {
        icon: 'simple-icon-layers',
        label: 'menu.setting.exam-types',
        to: `${adminRoot}/setting/examTypes`,
        permission: [UserPermission.ExamTypes],

      },
      {
        icon: 'simple-icon-layers',
        label: 'السنوات الدراسية',
        to: `${adminRoot}/setting/grades`,
        permission: [UserPermission.GradeNames],

      },
      {
        icon: 'iconsminds-calendar-1',
        label: 'menu.setting.group-categories',
        to: `${adminRoot}/setting/groupCategories`,
        permission: [UserPermission.GroupCategories],

      },
      {
        icon: 'iconsminds-calendar-1',
        label: 'menu.setting.groups',
        to: `${adminRoot}/setting/groups`,
        permission: [UserPermission.Groups],

      },
      {
        icon: 'iconsminds-student-male-female',
        label: 'menu.setting.terms',
        to: `${adminRoot}/setting/terms`,
        permission: [UserPermission.Terms],

      }
      ,
      //sections
      {
        icon: 'iconsminds-student-hat',
        label: 'menu.setting.section-names',
        to: `${adminRoot}/sections`,
        permission: [UserPermission.SectionsNames],

      },
      , {
        icon: 'simple-icon-layers',
        label: 'menu.setting.positions',
        to: `${adminRoot}/setting/positions`,
        permission: [UserPermission.Jobs],

      },
      , {
        icon: 'simple-icon-layers',
        label: 'menu.setting.infractionReasons',
        to: `${adminRoot}/setting/infractionReasons`,
        permission: [UserPermission.InfractionsTypes],

      },
      , {
        icon: 'simple-icon-layers',
        label: 'menu.setting.absenceCause',
        to: `${adminRoot}/setting/absenceCauses`,
        permission: [UserPermission.AttendaceTypes],

      },
      , {
        icon: 'simple-icon-layers',
        label: 'menu.setting.deactivation-causes',
        to: `${adminRoot}/setting/deactivationCauses`,
        permission: [UserPermission.DeactivationTypes],

      },
      // {
      //   icon: 'iconsminds-coins',
      //   label: 'forms.fees-templates',
      //   to: `${adminRoot}/setting/premiumeSetting`,
      //   permission: [UserPermission.DeactivationTypes],

      // },
      // {
      //   icon: 'iconsminds-coins',
      //   label: 'menu.setting.academic-stage-fees',
      //   to: `${adminRoot}/setting/academicStageFees`,
      //   permission: [UserPermission.DeactivationTypes],
      // },
      {
        icon: 'iconsminds-coins',
        label: 'menu.setting.discounts',
        to: `${adminRoot}/setting/discounts`,
        permission: [UserPermission.discounts],
      },
      {
        icon: 'iconsminds-coins',
        label: 'menu.setting.academic-stage',
        to: `${adminRoot}/setting/academic-stage`,
        permission: [UserPermission.academic_stages],
      },
      /*{
        icon: 'iconsminds-coins',
        label: 'menu.setting.factuly',
        to: `${adminRoot}/setting/premiumeSetting`,
    permission: [UserPermission.DeactivationTypes],

      },*/
      //lms tab
      {
        icon: 'simple-icon-screen-desktop',
        label: 'menu.courses',
        to: `${adminRoot}/lms`,
        permission: [UserPermission.courses],
        subs: [
          {
            icon: 'iconsminds-blackboard',
            label: 'menu.setting.courses',
            to: `${adminRoot}/setting/courses`,
            permission: [UserPermission.courses],
          },
          {
            icon: 'iconsminds-profile',
            label: 'menu.course-type',
            to: `${adminRoot}/setting/courseType`,
            permission: [UserPermission.CoursesTypes],
          },
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu.setting.levels',
        to: `${adminRoot}/setting/levels`,
      },
    ],
  },






  //schedul
  // {
  //   icon: 'simple-icon-calendar',
  //   label: 'menu.schedule',
  //   to: `${adminRoot}/schedule`,

  //   subs: [
  //     {
  //       icon: 'simple-icon-calendar',
  //       label: 'menu.schedule',
  //       to: `${adminRoot}/schedule`,
  //     },
  //     {
  //       icon: 'simple-icon-calendar',
  //       label: 'menu.teaching-plane',
  //       to: `${adminRoot}/schedule/addTeachingPlane`,
  //     },
  //   ],
  // },

];
export default data;
